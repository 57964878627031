import Analytics from 'analytics'
// @ts-ignore
import simpleAnalyticsPlugin from '@analytics/simple-analytics'
import { useContext, useEffect } from 'react'
import { SettingsContext } from '../Context/SettingsContext'
import TagManager from 'react-gtm-module'

interface metaInterface {
    version: string | undefined
    flow: string
    leadsource: number | undefined
    pagesource: string
}

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
        sa_event?(name: string, meta: metaInterface): void
    }
}

const useAnalytics = ({ flow }: { flow: string }) => {
    const { leadsource, version } = useContext(SettingsContext)

    const emitEvent = (name: string, additionalMeta?: any) => {
        window.sa_event &&
            window.sa_event(name, {
                leadsource,
                version,
                flow,
                pagesource: window.location.href,
                ...additionalMeta
            })

        const dataLayerObj = {
            event: 'houseScanDataLayerEvent',
            houseScanType: window.location.href,
            houseScanLabel: name,
            houseScanAction: additionalMeta ? Object.keys(additionalMeta) : '',
            houseScanValue: '',
            houseScanVersion: version,
            houseScanFlow: flow
        }

        if (window.dataLayer) {
            window.dataLayer.push(dataLayerObj)
        } else console.log('tag manager not initialised')
    }

    useEffect(() => {
        const gtmId = process.env.REACT_APP_GTM_ID
        const tagManagerArgs = {
            gtmId: gtmId || ''
        }
        TagManager.initialize(tagManagerArgs)

        Analytics({
            app: 'solease_housescan',
            plugins: [
                simpleAnalyticsPlugin({
                    hostname: 'solease.nl'
                })
            ]
        })

        emitEvent('Housescan loaded')
    }, [])

    return { emitEvent }
}

export default useAnalytics
