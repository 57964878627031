import {
    cavityInsulation,
    cavityInsulation2,
    chargingStation,
    chargingStation2,
    electricCar,
    fivePersons,
    floorInsulation,
    floorInsulation2,
    fourPersons,
    glassInsulation,
    glassInsulation2,
    homeBattery,
    homeBattery2,
    onePerson,
    roofInsulation,
    roofInsulation2,
    solarPanels,
    solarPanels2,
    threePersons,
    twoPersons,
    waterpump,
    waterpump2
} from '../assets/Icons'

export const energyOptions = [
    {
        id: 'solar',
        description: 'service_solar_description',
        imgSrc: solarPanels,
        img2Src: solarPanels2,
        type: 'energySolution',
        available: true,
        recommendedString: 'service_solar_recommended_string',
        notInterestedString: 'service_solar_notinterested_string'
    },
    {
        id: 'homebattery',
        description: 'service_homebattery_description',
        imgSrc: homeBattery,
        img2Src: homeBattery2,
        type: 'energySolution',
        available: true,
        recommendedString: 'service_homebattery_recommended_string',
        notInterestedString: 'service_homebattery_notinterested_string'
    },
    {
        id: 'electric_car',
        description: 'service_electric_car_description',
        imgSrc: electricCar,
        img2Src: electricCar,
        type: 'energySolution',
        available: false
    },
    {
        id: 'charging_station',
        description: 'service_chargingstation_description',
        imgSrc: chargingStation,
        img2Src: chargingStation2,
        type: 'energySolution',
        savings: '0.15',
        savingsDescription: 'service_chargingstation_savings_description',
        available: true,
        recommendedString: 'service_chargingstation_recommended_string',
        notInterestedString: 'service_chargingstation_notinterested_string'
    },
    {
        id: 'heatpump',
        description: 'service_heatpump_description',
        imgSrc: waterpump,
        img2Src: waterpump2,
        type: 'energySolution',
        available: true,
        recommendedString: 'service_heatpump_recommended_string'
    },
    {
        id: 'glass',
        description: 'service_glass_description',
        adviceCardDescription: 'service_glass_advice_description',
        imgSrc: glassInsulation,
        img2Src: glassInsulation2,
        type: 'energyLossPrevention',
        available: true,
        recommendedString: 'service_glass_recommended_string',
        notInterestedString: 'service_glass_notinterested_string',
        types: [
            'insulation_type_only_glass',
            'insulation_type_double_glazed_ground_floor',
            'insulation_type_double_glazed_whole_house',
            'insulation_type_hr++'
        ]
    },
    {
        id: 'wall',
        description: 'service_wall_description',
        imgSrc: cavityInsulation,
        img2Src: cavityInsulation2,
        type: 'energyLossPrevention',
        available: true,
        recommendedString: 'service_wall_recommended_string',
        notInterestedString: 'service_wall_notinterested_string',
        types: [
            'insulation_type_not_insulated',
            'insulation_type_moderately_insulated',
            'insulation_type_well_insulated'
        ]
    },
    {
        id: 'roof',
        description: 'service_roof_description',
        imgSrc: roofInsulation,
        img2Src: roofInsulation2,
        type: 'energyLossPrevention',
        available: true,
        recommendedString: 'service_roof_recommended_string',
        notInterestedString: 'service_roof_notinterested_string',
        types: [
            'insulation_type_not_insulated',
            'insulation_type_moderately_insulated',
            'insulation_type_well_insulated'
        ]
    },
    {
        id: 'ground',
        description: 'service_ground_description',
        imgSrc: floorInsulation,
        img2Src: floorInsulation2,
        type: 'energyLossPrevention',
        available: true,
        recommendedString: 'service_ground_recommended_string',
        notInterestedString: 'service_ground_notinterested_string',
        types: [
            'insulation_type_not_insulated',
            'insulation_type_moderately_insulated',
            'insulation_type_well_insulated'
        ]
    }
]

export const familySizeOptions = [
    {
        label: 'label_1person',
        value: { kwh: 1100, gas: 1000, size: 1 },
        img: onePerson
    },
    {
        label: 'label_2person',
        value: { kwh: 2800, gas: 1200, size: 2 },
        img: twoPersons
    },
    {
        label: 'label_3person',
        value: { kwh: 3300, gas: 1500, size: 3 },
        img: threePersons
    },
    {
        label: 'label_4person',
        value: { kwh: 3800, gas: 1700, size: 4 },
        img: fourPersons
    },
    {
        label: 'label_5person',
        value: { kwh: 4100, gas: 1900, size: 5 },
        img: fivePersons
    }
]

export const stepEnums = {
    1: 'Step 1',
    2: 'Step 2',
    8: 'Step 2 Error',
    3: 'Step 3',
    4: 'Step 4',
    5: 'Step 5',
    6: 'Step 6',
    7: 'Step 7',
    9: 'Step 4,5',
    10: 'DCT Step 1',
    11: 'DCT Step 2',
    12: 'DCT Step 3',
    19: 'DCT Step 3 Error',
    13: 'DCT Step 4',
    15: 'DCT Step 3B',
    16: 'DCT Step 5p'
}

export const excludeZipcodes = [
    '1795',
    '1796',
    '1797',
    '8899',
    '8890',
    '8891',
    '8892',
    '8893',
    '8894',
    '8895',
    '8896',
    '8897',
    '8880',
    '8881',
    '8882',
    '8883',
    '8884',
    '8885',
    '9160',
    '9161',
    '9162',
    '9163',
    '9164',
    '9166'
]

export const houseTypeOptions = [
    {
        label: 'housetype_terraced_house',
        value: 'terraced_house',
        gasRatio: 0.61,
        electricityRatio: 0.7
    },
    {
        label: 'housetype_corner_house',
        value: 'corner_house',
        gas_ratio: 1.02,
        electricity_ratio: 1.02
    },
    {
        label: 'housetype_2_under_1_roof',
        value: 'under_1_roof',
        gas_ratio: 1.2,
        electricity_ratio: 1.15
    },
    {
        label: 'housetype_detached_house',
        value: 'detached_house',
        gas_ratio: 1.56,
        electricity_ratio: 1.43
    },
    {
        label: 'housetype_appartment',
        value: 'appartment',
        gas_ratio: 0.61,
        electricity_ratio: 0.7
    },
    {
        label: 'housetype_upstairs_appartment',
        value: 'upstairs_appartment',
        gas_ratio: 1,
        electricity_ratio: 1
    },
    {
        label: 'housetype_downstairs_appartment',
        value: 'downstairs_appartment',
        gas_ratio: 1,
        electricity_ratio: 1
    }
]
