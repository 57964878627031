export const onePerson = require('assets/icons/1_person.png')
export const twoPersons = require('assets/icons/2_persons.png')
export const threePersons = require('assets/icons/3_persons.png')
export const fourPersons = require('assets/icons/4_persons.png')
export const fivePersons = require('assets/icons/5_persons.png')
export const addressNotFoundImage = require('assets/icons/addressNotFound.png')
export const arrowIcon = require('assets/icons/arrow_icon.svg').default
export const cavityInsulation =
    require('assets/icons/cavity_insulation.svg').default
export const cavityInsulation2 =
    require('assets/icons/cavity_insulation_2.svg').default
export const chargingStation =
    require('assets/icons/charging_station.svg').default
export const chargingStation2 =
    require('assets/icons/charging_station_2.svg').default
export const checkboxChecked =
    require('assets/icons/checkbox_checked.svg').default
export const checkedDataIcon = require('assets/icons/checkedData.svg').default
export const checkedIcon = require('assets/icons/checkedIcon.svg').default
export const closeButton = require('assets/icons/xIcon.svg').default
export const co2SavingsImg = require('assets/icons/co2_savings.png')
export const completeAdvice =
    require('assets/icons/complete_advice.svg').default
export const discussWishesImg =
    require('assets/icons/discuss_wishes.svg').default
export const districtHeating =
    require('assets/icons/district_heating.svg').default
export const districtHeating2 =
    require('assets/icons/district_heating_2.svg').default
export const electricCar = require('assets/icons/electric_car.svg').default
export const expectedYieldImg = require('assets/icons/expected_yield.png')
export const financialSavingsImg =
    require('assets/icons/financial_savings.svg').default
export const floorInsulation =
    require('assets/icons/floor_insulation.svg').default
export const floorInsulation2 =
    require('assets/icons/floor_insulation_2.svg').default
export const glassInsulation =
    require('assets/icons/glass_insulation.svg').default
export const glassInsulation2 =
    require('assets/icons/glass_insulation_2.svg').default
export const globeIcon = require('assets/icons/globe.svg').default
export const heatingIcon = require('assets/icons/heating_icon.png')
export const homeBattery = require('assets/icons/home_battery.svg').default
export const homeBattery2 = require('assets/icons/home_battery_2.svg').default
export const infoIcon = require('assets/icons/info.svg').default
export const isolatie = require('assets/icons/isolatie.svg').default
export const mapPinIcon = require('assets/icons/map_pin.svg').default
export const markerImage = require('assets/icons/pinIcon.png')
export const minusIcon = require('assets/icons/minus.svg').default
export const plusIcon = require('assets/icons/plus.svg').default
export const receiveQuoteImg = require('assets/icons/receive_quote.svg').default
export const roofInsulation =
    require('assets/icons/roof_insulation.svg').default
export const roofInsulation2 =
    require('assets/icons/roof_insulation_2.svg').default
export const sharpenAdviceImg =
    require('assets/icons/sharpen_advice.svg').default
export const solarGrayImg = require('assets/icons/solar_gray.png')
export const solarImage = require('assets/icons/solar_panels.svg').default
export const solarPanels = require('assets/icons/solar_panels.svg').default
export const solarPanels2 = require('assets/icons/solar_panels_2.svg').default
export const subsidieImg = require('assets/icons/subsidie.svg').default
export const waterpump = require('assets/icons/water_pump.svg').default
export const waterpump2 = require('assets/icons/water_pump_2.svg').default
export const xIcon = require('assets/icons/xIcon.svg').default
