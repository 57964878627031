import React, {
    useCallback,
    useContext,
    useEffect,
    useReducer,
    useState
} from 'react'
import { stepEnums } from '../static'
import { SettingsContext } from './SettingsContext'
import useAnalytics from '../CustomHooks/useAnalytics'

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
        dataLayer: any
    }
}

interface StepperContextInterface {
    step: number
    flow: string
    isUpdateRequest: boolean
    dctResultsError: boolean
    dctManualOffer: boolean

    dispatchStep(type: string, choice?: string): void

    setDctManualOffer(manualOffer: boolean): void

    setIsUpdateRequest(isUpdateRequest: boolean): void

    emitEvent(label: string, additionalMeta?: any): void
}

export const StepperContext = React.createContext({} as StepperContextInterface)

const StepperContextProvider = (props: { children: React.ReactNode }) => {
    const { directFlow } = useContext(SettingsContext)

    const [flow, setFlow] = useState(directFlow || 'completeAdvice')
    const [dctResultsError, setDctResultsError] = useState(false)
    const [isUpdateRequest, setIsUpdateRequest] = useState(false)
    const [dctManualOffer, setDctManualOffer] = useState(false)

    const reducer = useCallback(
        (step: number, action: { type: string; choice?: string }) => {
            const step2Branching = () => {
                setFlow(action.choice || '')

                return action.choice === 'solar' ? 10 : 3
            }

            switch (action.type) {
                case 'next':
                    if (step === 1 && directFlow) {
                        setFlow(directFlow)

                        return directFlow === 'solar' ? 10 : 3
                    } else if (step === 4)
                        return flow === 'completeAdvice' ? 9 : 5
                    else if (step === 9) return 5
                    else return step + 1
                case 'back':
                    if (step === 3 && directFlow) return 1
                    else if (step === 9) return 4
                    else if (step === 5)
                        return flow === 'completeAdvice' ? 9 : 4
                    else if (step === 15) return 10
                    else return step - 1
                case 'start':
                    return 1
                case 'dct_back':
                    return directFlow ? 1 : 2
                case 'step2Branching':
                    return step2Branching()
                case 'addressError':
                    return 8
                case 'dct_results_err':
                    setDctResultsError(true)

                    return 15
                case 'dct_results_err_back':
                    return 11
                case 'dct_offer_confirmation':
                    return 7
                case 'dct_full_offer':
                    return 15
                case 'redirect_personal_info':
                    setIsUpdateRequest(true)
                    setFlow('completeAdvice')

                    return 6
                case 'dct_start':
                    return 10
                default:
                    throw new Error()
            }
        },
        [directFlow, flow]
    )

    const [step, dispatch] = useReducer(reducer, 1)

    const { emitEvent } = useAnalytics({ flow })

    const dispatchStep = useCallback(
        (type: string, choice?: string) => dispatch({ type, choice }),
        []
    )

    useEffect(() => {
        setTimeout(() => {
            emitEvent(`${stepEnums[step as keyof typeof stepEnums]} loaded`)
        }, 300)
    }, [step])

    return (
        <StepperContext.Provider
            value={{
                step,
                dispatchStep,
                flow,
                isUpdateRequest,
                setIsUpdateRequest,
                dctResultsError,
                dctManualOffer,
                setDctManualOffer,
                emitEvent
            }}
        >
            {props.children}
        </StepperContext.Provider>
    )
}

export default StepperContextProvider
